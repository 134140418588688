import { registerApplication, start } from "single-spa";
import {
  constructRoutes,
  constructApplications,
  constructLayoutEngine,
} from "single-spa-layout";

const routes = constructRoutes({
  routes: window.layout,
  redirects: window.redirects,
  containerEl: "body",
  base: "/",
});
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
start();
